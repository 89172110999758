import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, replaceLoclizationLabel, minifyJSON } from '../../../helpers/CommonHelper';
import { useEditLineProductMutation } from '../../../services/cart';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import LoadingWrapper from '../../components/products/LoadingWrapper';
import { useGetAllProductsAttributesQuery, useGetColorInventoryQuery, useGetProductQuery } from '../../../services/product';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Alert, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import ProductSizeSelector from '../products/ProductSizeSelector';
import { capitalizeFirstLetter, makePriceRoundToTwoPlaces } from '../../../helpers/ConversionHelper';
import { QuantityManipulator } from './QuantityManipulator';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import SelectSearch from '../../components/shared/SelectSearch.js';
import PbeList from '../../components/shared/PbeList.js';
import { ToggleContext } from '../../../ToggleProvider';

const MiniEditModal = (props) => {
    const dispatch = useAppDispatch();
    const {
        toggle,
        open,
        cartProduct: {
            DepartmentId: cartCategoryId,
            productId: cartSku,
            images: cartImages,
            lineItemId: cartLineItemId,
            EmbTemplateId: cartSelectedEmbroideryIndex,
            quantity: cartQuantity,
            sizeShortName: cartSizeId,
            colorName: cartColorId,
            HexCode: cartHexCode,
            embroidery: cartEmbroidery,
            productPrice: cartProductPrice,
        }
    } = props;
    const productId = cartSku?.split('-')[0];
    const productActivity = useSelector(state => state.pdpReducer);
    const { data: product, isFetching: isProductLoading } = useGetProductQuery(!cartSku ? skipToken : { productId, categoryId: cartCategoryId });
    const { data: colorInventory, isFetching: isColorInventoryLoading } = useGetColorInventoryQuery(
        { 
            colorId: productActivity.selectedColorId, 
            product 
        }, { refetchOnMountOrArgChange: true });
    const { data: productAllAttributes } = useGetAllProductsAttributesQuery(!isProductLoading && product ? product : skipToken);
    const [sizeErrorOnAction, setSizeErrorOnAction] = useState(false);
    const [updateLineItem, { isLoading: isEditingLineItem }] = useEditLineProductMutation();
    const [productReady, setProductReady] = useState(false);
    const [feedback, setFeedback] = useState({ message: '', type: '', isOpen: false });
    const [LocalizationLabelsArray] = useState([]);
    
    const user = useSelector(state => state.userReducer.user);
    const siteInfo = useSelector(state => state.commonReducer);
    const currentCart = useSelector(state => state.cartReducer);
    const currentPbeMapping = currentCart?.products?.map((item) => {
        var style = item.productId?.split('-')[0];
        var currentStyle = productActivity?.skuBits[0];
        if (style === currentStyle) {
            return {
                Name: item.SelectedEmployee,
                Color: item.colorName,
                ColorLongName: item.ColorLongName,
                Size: item.sizeShortName,
                Qty: item.quantity
            }
        } else return null;
    }).filter(x => !!x);

    const getSelectedEmpployee = () => {
        return user?.Recipients?.map(o => `${o.FirstName},${o.LastName}`)?.indexOf(props.cartProduct.SelectedEmployee) ?? -1;
    };
    const { isToggled, setIsToggled } = useContext(ToggleContext);

    useEffect(() => {
        if (!isProductLoading) {
            dispatch(rootAction.pdpActions.setActiveColor({ id: cartColorId }));
            dispatch(rootAction.pdpActions.setActiveHexCode(cartHexCode));
            dispatch(rootAction.pdpActions.setActiveEmbroideryPresets(cartSelectedEmbroideryIndex));
            dispatch(rootAction.pdpActions.setCategoryAndProductId({ productId, categoryId: cartCategoryId }));
            dispatch(rootAction.pdpActions.setEmployee(user.Recipients?.[getSelectedEmpployee()] ?? {}))
        }
    }, [isProductLoading]);

    useEffect(() => {
        var hasColors = product && product.colors && product.colors.length > 0;
        if (!isColorInventoryLoading && (colorInventory || !hasColors)) {
            setProductReady(true);
        }
    }, [isColorInventoryLoading, colorInventory]);

    useEffect(() => {
        return () => {
            dispatch(rootAction.pdpActions.reset());
        }
    }, []);
    const closeBtn = <button className="close" onClick={toggle}><span className="icon icon-close"></span></button>;

    const handleColorChange = async (inColor) => {
        dispatch(rootAction.pdpActions.setActiveColor(inColor));
        dispatch(rootAction.pdpActions.setActiveHexCode(product?.colors?.find(color => color.id === inColor.id)?.hexCode));
    }

    const handleUpdateLineItem = async () => {
        setFeedback({ message: '', type: '', isOpen: false });
        var productSelectedAttributesTemp = [];

        if (productActivity.selectedColorId) {
            productSelectedAttributesTemp.push({ ProductId: cartSku, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['Color'], PrimaryKeyValue: productActivity.selectedColorId });
        }

        if (productActivity.selectedSizeId) {
            productSelectedAttributesTemp.push({ ProductId: cartSku, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['Size'], PrimaryKeyValue: productActivity.selectedSizeId });
        }

        //--check if size selected
        if (!productActivity.selectedSizeId && product.sizes.length > 0) {
            setSizeErrorOnAction(true);
            return;
        }

        //--validate all others attributes except color and size because its already validated above
        let localAttributes = productAllAttributes?.filter(x => x.ProductAttributeID !== Config.PRODUCT_ATTRIBUTE_ENUM['Color'] && x.ProductAttributeID !== Config.PRODUCT_ATTRIBUTE_ENUM['Size']);
        for (let index = 0; index < localAttributes?.length; index++) {
            const elementAttr = localAttributes[index];
            if (elementAttr?.IsRequiredAttribute !== undefined && elementAttr?.IsRequiredAttribute === true) {
                if (!productSelectedAttributesTemp.some(x => x.ProductAttributeID === elementAttr.ProductAttributeID)) {
                    return false;
                }
            }
        }

        let selectedEmbIdx = -1;
        //--Push the embroidery if applicable and selected
        if (cartSelectedEmbroideryIndex >= 0 && cartEmbroidery) {
            selectedEmbIdx = cartSelectedEmbroideryIndex;
            if (product.embroideryEnabled) {
                var embDescr = product.embroideryPresets.TemplateDescriptor;
                if (embDescr && embDescr.Templates && embDescr.Templates.length > 0) {
                    var embSelected = embDescr.Templates[selectedEmbIdx];
                    var embStrSelected = minifyJSON(JSON.stringify(cartEmbroidery ?? embSelected.VASData));
                    var embSelectedPrice = embSelected.Price;
                    productSelectedAttributesTemp.push({ ProductId: cartSku, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['EmbroideryMeta'], PrimaryKeyValue: embStrSelected });
                    productSelectedAttributesTemp.push({ ProductId: cartSku, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['EmbroideryPrice'], PrimaryKeyValue: embSelectedPrice });
                }
            }
        }

        let selectedLongColorName = "No Color";

        if (product && product.colors && product.colors.length > 0) {
            for (let o = 0; o < product.colors.length; o++) {
                if (product.colors[o].id === productActivity.selectedColorId) {
                    selectedLongColorName = product.colors[o].name;
                    break;
                }
            }
        }

        if (user.Recipients?.length > 0) {
            if (!productActivity.selectedEmployee) {
                setFeedback({ 
                    message: 'As an Admin, you must select a recipient for this product before adding to Cart.', 
                    type: 'error', show: true
                });
                return;
            }
        }

        try {
            await updateLineItem({
                lineItemId: cartLineItemId,
                productId: product.id,
                quantity: productActivity.quantity,
                productSelectedAttributes: productSelectedAttributesTemp,
                defaultImage: product?.images?.[0].url,
                CategoryId: cartCategoryId,
                HexCode: productActivity.selectColorHexCode,
                ColorLongName: selectedLongColorName,
                SelectedEmbTemplateId: selectedEmbIdx,
                availabilityMessage: productActivity.backOrderMessage,
                itemClass: product?.itemClass,
                selectedEmployee: productActivity.selectedEmployee
            }).unwrap().then((res) => {
                dispatch(rootAction.cartActions.setCart(res));
                dispatch(rootAction.pdpActions.reset());
            });

            toggle(cartLineItemId);
        } catch (err) {
            setFeedback({ message: err.data.message, type: 'danger', isOpen: true });
        }
    }

    const [colorDropdownOpen, setColorDropdownOpen] = useState(false);
    const toggleColorDropdown = () => setColorDropdownOpen(prevState => !prevState);

    return (
        <Modal isOpen={open} toggle={toggle} className='quick-view-dialog'>
            <LoadingWrapper isLoading={isProductLoading || isEditingLineItem}>
                <ModalHeader toggle={toggle} close={closeBtn} />
                <ModalBody>
                    <Alert color={feedback.type} isOpen={feedback.isOpen}>
                        {feedback.message}
                    </Alert>
                    {product && <>
                        <div className='product-quickview product-variant product-wrapper product-detail'>
                            <div className="quickview-top row mb-4">
                                <div className="col-12 col-md">
                                    <span className='style'>Style # {productActivity?.skuBits.filter(b => b !== '').join('-')}</span>
                                    <h1 className="product-name">{product.name}</h1>
                                </div>
                                {!isToggled&&
                                <div className="col-12 col-md-auto text-right price">
                                    <span>{GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(productActivity?.selectedSizeId && productActivity?.currentPrice > 0 ? productActivity.currentPrice : (cartProductPrice / cartQuantity))}</span>
                                </div>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="primary-images col-12 col-md-auto">
                                <div className="slide">
                                    {
                                        (
                                            () => {
                                                const firstImg = productActivity?.selectedColorId ? product?.images?.filter((item) => (productActivity?.selectedColorId ?? product.images[0].colorId) === item.colorId)?.[0] : cartImages?.[0];
                                                return (
                                                    <img
                                                        className="img-fluid"
                                                        src={`${firstImg?.url}?sw=350&sh=522&sm=fit`}
                                                    />
                                                )
                                            }
                                        )()
                                    }
                                </div>
                            </div>
                            <div className='col-12 col-md-auto ml-auto d-flex mt-3 mt-md-0'>
                                <div className='detail-panel d-flex flex-column'>
                                    {product?.fitList?.length > 0 &&
                                        <div className='row mt-0'>
                                            <div className="col product-fit d-flex mb-2">
                                                {product?.fitList?.map((item) => {
                                                    const itemSuffix = item[0] !== 'R' ? item[0] : '';
                                                    const urlViewDetailNext = `/${getLanguageCodeFromSession()}/product-detail/${product.style}${itemSuffix}/${product.category ?? "shop"}/${_.kebabCase(product.name)}`;
                                                    return (
                                                        <Link key={item.toString()} to={urlViewDetailNext} className={product.currentFit === item ? 'btn btn-sm btn-outline-primary active' : 'btn btn-sm btn-outline-primary'}>
                                                            {capitalizeFirstLetter(item)}
                                                        </Link>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {product?.colors?.length > 0 &&    
                                        <div className='row mt-0'>
                                            <div className='col'>
                                                <div className='attribute'>
                                                    <span className="size-attribute-title label d-block">Color</span>
                                                    <div className='product-description py-2'>
                                                        <Dropdown isOpen={colorDropdownOpen} toggle={toggleColorDropdown}>
                                                            <DropdownToggle onClick={(e) => e.target.blur()} color="light" className='btn-dropdwon-swatches btn-block d-flex justify-content-between align-items-center' caret>
                                                                <div className='d-flex align-items-center'>
                                                                    <span
                                                                        className={`color-attribute swatch-circle d-inline-block rounded-circle mr-2 ${productActivity.selectColorHexCode.toUpperCase() === '#FFFFFF' ? 'swatch-white' : ''}`}
                                                                        style={{
                                                                            [productActivity.selectColorHexCode.split("_").length > 1 ? 'background' : 'backgroundColor']: productActivity.selectColorHexCode.split("_").length > 1 ? `linear-gradient(to right,${productActivity.selectColorHexCode.split("_")[0]} 70%, ${productActivity.selectColorHexCode.split("_")[1]} 0)` : productActivity.selectColorHexCode
                                                                        }}
                                                                    >
                                                                    </span>
                                                                    {product.colors.find(c => c.id === productActivity.selectedColorId)?.name ?? 'No Color'}
                                                                </div>
                                                            </DropdownToggle>
                                                            <DropdownMenu className='dropdown-swatches has-custom-scrollbar'>
                                                                {product?.colors?.map((item) => {
                                                                    return (
                                                                        <DropdownItem key={item.id} onClick={() => handleColorChange(item)} className='d-flex align-items-center'>
                                                                            <span
                                                                                className={`color-attribute swatch-circle d-inline-block rounded-circle mr-2 ${item.hexCode.toUpperCase() === '#FFFFFF' ? 'swatch-white' : ''}`}
                                                                                style={{ [item.hexCode.split("_").length > 1 ? 'background' : 'backgroundColor']: item.hexCode.split("_").length > 1 ? `linear-gradient(to right,${item.hexCode.split("_")[0]} 70%, ${item.hexCode.split("_")[1]} 0)` : item.hexCode }}
                                                                            ></span>
                                                                            {item.name}
                                                                        </DropdownItem>
                                                                    )
                                                                })}
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    <div className='row mt-0'>
                                        <div className='col'>
                                            <div className='attribute'>
                                                <LoadingWrapper isLoading={isColorInventoryLoading}>
                                                    <ProductSizeSelector
                                                        sizes={product?.sizes}
                                                        initialSizeId={cartSizeId}
                                                        colorInventory={colorInventory}
                                                        hasError={sizeErrorOnAction}
                                                        isLoading={isColorInventoryLoading}
                                                        productAttributes={productAllAttributes}
                                                        setHasError={setSizeErrorOnAction} />
                                                </LoadingWrapper>
                                            </div>
                                        </div>
                                    </div>
                                    {siteInfo.isPBEEnabled && user.Recipients?.length > 0 ?
                                        <>
                                            <div className="pbeSelection custome-pbeSelector py-2">
                                                <SelectSearch 
                                                    id="pbe-selector"
                                                    label="Who is this garment for:"
                                                    placeholder="Select employee"
                                                    callback={(ind) => dispatch(rootAction.pdpActions.setEmployee(user.Recipients?.[ind] ?? {}))}
                                                    defaultValue={getSelectedEmpployee()}
                                                    options={user?.Recipients?.map((item, index) => {
                                                        return ({
                                                            value: {index}, 
                                                            label: `${item.LastName}, ${item.FirstName} ${(item.Address == null || item.Address.length == 0) ? '' : ('- ' + item.Address)}`
                                                        })
                                                    })} 
                                                    styleprop={true}
                                                    employeeDropdown={true}
                                                    />
                                            </div>
                                            {/* {true ?  */}
                                            {siteInfo.displayPBERecipientListOnPDP &&
                                                <PbeList id="pbe-assigned-employee-list" 
                                                    employeeList={currentPbeMapping} 
                                                    selectedEmployee={props.cartProduct.SelectedEmployee}>
                                                </PbeList>
                                            }
                                        </>
                                        :
                                        <></>
                                    }

                                    {productActivity.backOrderMessage ?
                                        <div className='row mt-md-auto'>
                                            <div className="availability-msg col mb-1 text-right">
                                                <small><i>{productActivity.backOrderMessage}</i></small>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    <div className='row'>
                                        <div className='col-12 col-md-7 mb-4 mb-md-0'>
                                            {productReady &&
                                                <QuantityManipulator
                                                    productDetail={product}
                                                    initialProductActivity={productActivity}
                                                    colorInventory={colorInventory}
                                                    initialQuantity={cartQuantity}
                                                    initialLineItemId={cartLineItemId}
                                                />
                                            }
                                        </div>
                                        <div className='col-12 col-md-5 mb-4 mb-md-0'>
                                            <Button onClick={handleUpdateLineItem} className='w-100 w-m-auto' 
                                            disabled={productActivity?.quantity < 1 || isColorInventoryLoading}>Update</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </ModalBody>
            </LoadingWrapper>
        </Modal>
    )
}

export default MiniEditModal;
