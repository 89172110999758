import React, { createContext, useState, useEffect, useContext } from 'react';
import LoadingScreen from './web/components/shared/LoadingScreen';
import { useSelector } from 'react-redux';

export const ToggleContext = createContext();

export const useSwitching = () => {
  return useContext(ToggleContext);
};

export const ToggleProvider = ({ children }) => {
  const { sizingPortalEnabled } = useSelector(state => state.commonReducer);
  
  const [isToggled, setIsToggled] = useState(() => {
    // Get the initial state from localStorage or default to true
    // true => Allotment
    // false => Self-Pay
    const saved = localStorage.getItem('toggleSizing');
    return saved !== null ? JSON.parse(saved) : sizingPortalEnabled;
  });

  const [intentToSwitch, setIntentToSwitch] = useState(false);

  const [isSwitching, setIsSwitching] = useState(false);

  const showSwitching = () => setIsSwitching(true);
  const hideSwitching = () => setIsSwitching(false);

  const setIntentToSwitchOn = () => setIntentToSwitch(true);
  const setIntentToSwitchOff = () => setIntentToSwitch(false);

  useEffect(() => {
    // Save the state to localStorage whenever it changes
    localStorage.setItem('toggleSizing', JSON.stringify(isToggled));
  }, [isToggled]);

  return (
    isSwitching ? <>
     <div className='text-center'>
      <LoadingScreen loading={true}  detailText={isToggled?'Switching to Allotment Perspective...' : 'Switching to Self-Pay Perspective...'}/>
    </div></> : <>
    <ToggleContext.Provider value={{ isToggled, setIsToggled, showSwitching, hideSwitching, intentToSwitch, setIntentToSwitch}}>
      {children}
    </ToggleContext.Provider>
    </>
  );
};