import { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';

import { useSelector } from 'react-redux';
import { Alert, Button, Card, CardHeader, CardTitle, CardSubtitle, CardBody, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap'
import NewCustomerCard from './NewCustomerCard';
import { validateEmail } from '../../../helpers/ValidationHelper';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import Seo from '../../components/shared/Seo';
import { useLoginMutation, useLoginExtQuery } from '../../../services/auth';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import rootAction from '../../../stateManagment/actions/rootAction';
import UpdateEmployeeIdLoginInfo from '../../views/login/UpdateEmployeeIdLoginInfo.js';
import { api } from '../../../services/api';
import SSOCustomerCard from './SSOCustomerCard';
import { useGetContentAssetQuery } from '../../../services/content';
import { ToggleContext,useSwitching } from '../../../ToggleProvider';
import { useSwitchSessionMutation } from '../../../services/auth';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showError, setShowError] = useState(false);
    const [showUpdateEmployeeIdLoginInfo, setShowUpdateEmployeeIdLoginInfo] = useState(false);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [flashMsg, setFlashMsg] = useState('');
    const [ShowPassword, setShowPassword] = useState(true);
    const SignupOption = useSelector(state => state.commonReducer.selfRegister);
    const SsoCompany = useSelector(state => state.commonReducer.siteName);
    const SsoOption = useSelector(state => state.commonReducer.enableSSO);
    const SamlLoginEndpoint = useSelector(state => state.commonReducer.samlLoginEndpoint);
    const SamlLogoutEndpoint = useSelector(state => state.commonReducer.samlLogoutEndpoint);
    const LoginSystem = useSelector(state => state.commonReducer.loginSystem);
    const { lastVisitedUrl, lastUserId } = useSelector(state => state.commonReducer);
    const [formValid, setFormValid] = useState(null);
    const [login, { isFetching, isLoading }] = useLoginMutation();
    const loginExtDataRef = useRef();
    const { user, rememberMe, flashMessage } = useSelector(state => state.userReducer);
    const [formErrors, setFormErrors] = useState({});
    const [shippingAddress, setShippingAddress] = useState({ address1: "", address2: "", city: "", stateCode: "", countryCode: "US", postalCode: "", allotmentAddressLabel: "", allotmentAddressId: 0});
    const [phone, setPhone] = useState('');
    const [firstName, setFirstName] = useState('');
    const [pollCounter, setPollCounter] = useState(0);
    const [isExtLoginError, setIsExtLoginError] = useState(false);
    const [skipPolling, setSkipPolling] = useState(false);
    const [showErrorForEmpIdLogin, setShowErrorForEmpIdLogin] = useState(false);
    const [authenticationDone, setAuthenticationDone] = useState(null);
    const accountLoginIntroText = useSelector(state => state.commonReducer.cachedContentAssets?.['account-login-intro-text']);
    const ssoButtonText = useSelector(state => state.commonReducer.cachedContentAssets?.['sso-login-button-text']);
    const [isSsoEmployeeLoginInfoLoaded, setIsSsoEmployeeLoginInfoLoaded] = useState(false);
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const [switchSession, { isSwitching }] = useSwitchSessionMutation();
    const { showSwitching, hideSwitching } = useSwitching();
    const { setIntentToSwitch } = useContext(ToggleContext);

    const [searchParams] = useSearchParams();
    const qsParsed = {
        ExtLoginError: searchParams.get('ExtLoginError'),
        SP: searchParams.get('SP'),
        LoginSessionID: searchParams.get('LoginSessionID'),
        showUaLogin: searchParams.get('show') === '1'
    }
    const { data: loginExtData, isFetching: isExtLoading, isSuccess: isLoginExtSuccess, isUninitialized } = useLoginExtQuery({
        externalSP: qsParsed.SP,
        externalLoginSessionID: qsParsed.LoginSessionID,
        extLoginRetryCount : pollCounter,
        isToggled: isToggled
    }, {
        skip: skipPolling || !qsParsed.LoginSessionID || loginExtDataRef.current?.UserID,
        pollingInterval: 1000
    });

    const removeFormError = (e) => {
        delete formErrors[e];
        setFormErrors({ ...formErrors });
    };

    const employeeIdLogin = async (employeeId, password, isExternalLogin) => {
        if (isSsoEmployeeLoginInfoLoaded === true) {
            return;
        }
        setIsSsoEmployeeLoginInfoLoaded(true);
        dispatch(rootAction.commonAction.setLoading(true));
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };
        const param = {
            requestParameters: {
                employeeId: employeeId,
                password: password,
                isExternalLogin: isExternalLogin
            }
        };
        var response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_EMPLOYEE_ID_LOGIN_INFO'], null, param, headers, "POST", true);
        dispatch(rootAction.commonAction.setLoading(false));
        let parsed = JSON.parse(response.data.data);
        if (parsed.InvalidCredentials) {
            setShowError(true);
            setShowErrorForEmpIdLogin(true);
        }
        else {
            if (parsed.RequiresEmailUpdate) {
                setPhone(parsed.Phone);
                setFirstName(parsed.FirstName);
                setShippingAddress({
                    address1: parsed.ShippingAddress?.address1, 
                    address2: parsed.ShippingAddress?.address2, 
                    city: parsed.ShippingAddress?.city, 
                    stateCode: parsed.ShippingAddress?.state_code, 
                    countryCode: "US", 
                    postalCode: parsed.ShippingAddress?.postal_code,
                    allotmentAddressLabel: parsed.ShippingAddress?.allotmentAddressLabel,
                    allotmentAddressId: parsed.ShippingAddress?.allotmentAddressId
                });
                setShowUpdateEmployeeIdLoginInfo(true);  
            } else {
                submitLogin();
            }
        }
    };

    const submitLogin = () => {
        login({ Email, Password })
        .unwrap()
            .then(({UserID, JWTToken, RequiresEmailUpdate, AllotmentPerepectiveEnabled,SizingPortalEnabled}) => {
                if (UserID && JWTToken && !RequiresEmailUpdate) {
                    if(AllotmentPerepectiveEnabled && AllotmentPerepectiveEnabled==="true" && SizingPortalEnabled){
                        if(!isToggled){
                            switchToOtherPerspective();
                        }
                    }else{
                        if(isToggled){
                            switchToOtherPerspective();
                        }else{
                            const _lastVisitedUrl = lastUserId === UserID ? (lastVisitedUrl || '/') : '/';
                            var toRedirect = _lastVisitedUrl?.replace('order-review', 'checkout');            
                            navigate(toRedirect || '/');
                        }
                    }
                    setAuthenticationDone(true);
                    var liveChatElem = document.getElementById('genesys-messenger');
                    if (liveChatElem) {
                        liveChatElem.classList.remove('d-none');
                    }
                }
            }).catch(() => {
            setShowError(true);
            setShowErrorForEmpIdLogin(true);
        })
        .finally(() => {
            setFormValid(false);
        });
    };

    const switchToOtherPerspective = async() => {
        setIntentToSwitch(false);
        const Email = "NOOP";
        const Password = "NOOP";
        showSwitching();
        const currIsToggled = !isToggled;
        localStorage.setItem('toggleSizing', JSON.stringify(currIsToggled));
        setIsToggled(currIsToggled);
        switchSession({ Email, Password })
        .unwrap()
        .catch((error) => {
            setIsToggled(!isToggled);            
        })
        .finally(() => {
            const _lastVisitedUrl = lastUserId === user?.UserID ? (lastVisitedUrl || '/') : '/';
            var toRedirect = _lastVisitedUrl?.replace('order-review', 'checkout');
            navigate(toRedirect);
            hideSwitching();
        });
    }

    // what login page to show
    useEffect(() => {
        if (formValid) {
            if (LoginSystem == 1) {
                submitLogin();
            }
            if (LoginSystem == 2) {
                setIsSsoEmployeeLoginInfoLoaded(false);
                employeeIdLogin(Email, Password, false);
            }
        }
        return () => {
            setFormValid(null);
        }
    }, [formValid]);

    // Set Flash Message
    useEffect(() => {
        if (flashMessage) {
            setFlashMsg(flashMessage)
            dispatch(rootAction.userAction.resetFlashMessage());
        }
        if (user?.EmailAddress && rememberMe) {
            if (rememberMe) {
                setEmail(user.EmailAddress)
            }
        }
        if (user?.UserID && user?.JWTToken && !user?.RequiresEmailUpdate && authenticationDone === null) {
            setAuthenticationDone(true);
        } else {
            setAuthenticationDone(false);
        }
    }, [user]);

    // Reset PLP, cart and checkout
    useEffect(() => {
        dispatch(rootAction.plpActions.reset());
        dispatch(rootAction.cartActions.reset());
        dispatch(rootAction.checkoutActions.reset({ force: true }));
    }, [dispatch]);

    // SSO Poller
    useEffect(() => {
        if (isExtLoading) {
          setPollCounter((prevCounter) => {
            const newCounter = prevCounter + 1;
            if (newCounter >= 6) {
              setSkipPolling(true);
              return prevCounter;
            }
            return newCounter;
          });
        }
    }, [isExtLoading]);

    // SSO Login
    useEffect(() => {
        if(loginExtData && loginExtData.UserID && loginExtData.UserID.length>5 && loginExtData.JWTToken && loginExtData.JWTToken.length>5){
            setAuthenticationDone(true);
            if(loginExtData.AllotmentPerepectiveEnabled && loginExtData.AllotmentPerepectiveEnabled==="true" && loginExtData.SizingPortalEnabled){
                if(!isToggled){
                    switchToOtherPerspective();
                }
            }else{
                if(isToggled){
                    switchToOtherPerspective();
                }else{
                    const _lastVisitedUrl = lastUserId === loginExtData.UserID ? (lastVisitedUrl || '/') : '/';
                    var toRedirect = _lastVisitedUrl?.replace('order-review', 'checkout');            
                    navigate(toRedirect || '/');
                }                
            }
        } else {
            setAuthenticationDone(false);
            if (loginExtData && loginExtData.RequiresEmailUpdate) {
                employeeIdLogin(loginExtData.EmployeeID, '', true);        
            }
        }
    }, [loginExtData]);

    const submitLoginForm = (event) => {
        event.preventDefault();
        let errors = {}
        resetFlash()

        // Password Validation
        const regEx_Uppercase = new RegExp(/.*[A-Z]/);
        const regEx_Number = new RegExp(/.*\d/);
        const regEx_Length = new RegExp(/.{8,}$/);
        const regEx_SpecialChars = new RegExp(/.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/);

        const regEx_PasswordValid = new RegExp(`^(?=${[
            regEx_Length.source,
            regEx_Uppercase.source,
            regEx_Number.source,
            regEx_SpecialChars.source
        ].join(")(?=")}).*$`);

        if (LoginSystem === 1) {
            if (!Email || Email === '') errors.Email = 'Please enter your Email'
            else if (Email && !validateEmail(Email)) errors.Email = 'Please enter a valid Email address'
        } else {
            if (!Email || Email === '') errors.Email = 'Please enter your Employee ID'
        }

        if (!Password || Password === '') errors.Password = 'Please enter your Password'
        else if (regEx_PasswordValid.test(Password) === false) errors.Password = 'Sorry, the provided password does not match the required constraints.'

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            setFormValid(false);
        } else {
            setFormValid(true);
        }
    }

    var resetFlash = () => {
        setFlashMsg('')
        dispatch(rootAction.userAction.resetFlashMessage());
    }

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {
            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Login"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);

        if(qsParsed.ExtLoginError){
            setFlashMsg(qsParsed.ExtLoginError);
            setIsExtLoginError(true);
        }

        return () => {
            setShowError(false);
        }
    }, [qsParsed]);

    if (authenticationDone && user?.UserID && user?.JWTToken) {
        const _lastVisitedUrl = lastUserId === user.UserID ? (lastVisitedUrl || '/') : '/';
        navigate(lastUserId === user?.UserID ? (_lastVisitedUrl || '/') : '/');
    }
    
    if (showUpdateEmployeeIdLoginInfo) {
        const isExternalLogin = SsoOption === true && qsParsed.showUaLogin === false;
        return (<UpdateEmployeeIdLoginInfo 
                    shippingAddress={shippingAddress}
                    phone={phone}
                    firstName={firstName}
                    password={Password}
                    employeeId={isExternalLogin ? loginExtData.EmployeeID : Email}
                    enableSso={SsoOption}
                />);
    }
    else if (authenticationDone === false)
    return (
        <>
            <LoadingScreen loading={isFetching || isLoading || isExtLoading || (!isLoginExtSuccess && !isUninitialized)} />
            <Seo title="Login" description="Login" keywords="Login" />

            {/* <SiteBreadcrumb title="Login" /> */}

            <section id="maincontent" className="login-area pt-6 pt-md-8 pb-9">
                <div className="container-fluid">
                    <h2 className='account-page-title text-center mb-5'>
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "Account Login", "lbl_login_accountlogin")
                            :
                            "Account Login"
                        }
                    </h2>
                    {/* <p className='text-center mb-5'>No account, you can still track your order.</p> */}
                    <div className="row">
                        {SsoOption === true && qsParsed.showUaLogin === false ?
                            <div className="col col-md-6 mx-auto">
                                <SSOCustomerCard
                                    ssoLoginEp={SamlLoginEndpoint}
                                    ssoLogoutEp={SamlLogoutEndpoint}
                                    ssoCompany={SsoCompany}
                                    showExtError={skipPolling||isExtLoginError}
                                    loginCustomWording={accountLoginIntroText}
                                    ssoButtonText={ssoButtonText}
                                />
                            </div>
                            :
                            <div className={`${SignupOption ? 'col-12 col-md-6' : 'col-12 col-md-6 mx-auto'}`}>
                                <Card color="light" className="border-0 card--has-bg-color h-100">
                                    <CardHeader className='border-0 card-header--no-divisor'>
                                        {accountLoginIntroText?.length > 0 ?
                                            <>
                                            <div className="clearfix" dangerouslySetInnerHTML={{ __html: accountLoginIntroText }}></div>
                                            </>
                                            :
                                            <>
                                            <CardTitle tag="h2">
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Returning Customers", "lbl_login_title")
                                                    :
                                                    "Returning Customers"
                                                }
                                            </CardTitle>
                                            <CardSubtitle tag="p">
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Welcome back! Sign in below.", "lbl_login_welcomesubtitle")
                                                    :
                                                    "Welcome back! Sign in below."
                                                }
                                            </CardSubtitle>
                                            <CardSubtitle tag="p">
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "First-time logging in? Click Forgot Password and check your email for the reset link.", "lbl_login_resetpasswordinstructions")
                                                    :
                                                    "First-time logging in? Click Forgot Password and check your email for the reset link."
                                                }
                                            </CardSubtitle>
                                            </>
                                        } 
                                    </CardHeader>
                                    <CardBody>
                                        <Form className="login-form d-flex flex-column h-100" onSubmit={submitLoginForm} noValidate>
                                            <Alert color="danger" className={`${showError || showErrorForEmpIdLogin ? 'd-block' : 'd-none'}`}>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Oops! We don’t recognize the username or password you entered. Please try again and remember passwords are case sensitive.", "lbl_login_incorrectpasswordmessage")
                                                    :
                                                    "Oops! We don’t recognize the username or password you entered. Please try again and remember passwords are case sensitive."
                                                }
                                            </Alert>
                                            <Alert color="danger" className={`${!showError && flashMsg.length > 0 ? 'd-block' : 'd-none'}`}>
                                                {flashMsg}
                                            </Alert>

                                            <FormGroup className='required'>
                                                <Label>
                                                    {LoginSystem === 1 ?
                                                        LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Email", "lbl_login_email")
                                                            :
                                                            "Email"
                                                        :
                                                        LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Employee ID", "lbl_login_employeeid")
                                                            :
                                                            "Employee ID"
                                                    }
                                                </Label>
                                                <Input type={LoginSystem === 1 ? 'email' : 'text'} name='Email' id='Email' value={Email}
                                                    placeholder={LoginSystem === 1 ? 'Enter your email' : 'Enter your Employee ID'}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    onFocus={(e) => removeFormError(e.target.id)}
                                                    invalid={!!formErrors.Email}
                                                >
                                                </Input>
                                                <FormFeedback>{formErrors.Email}</FormFeedback>
                                            </FormGroup>

                                            <FormGroup className='required position-relative'>
                                                <Label>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Password", "lbl_login_password")
                                                        :
                                                        "Password"
                                                    }
                                                </Label>
                                                <Link to={''} className='btn-show-password btn-link position-absolute' onClick={(e) =>{e.preventDefault(); setShowPassword(!ShowPassword)}} tabIndex={-1}>
                                                    {ShowPassword ?
                                                        <>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Show", "lbl_login_show")
                                                                :
                                                                "Show"
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Hide", "lbl_login_hide")
                                                                :
                                                                "Hide"
                                                            }
                                                        </>
                                                    }
                                                </Link>
                                                {ShowPassword ?
                                                    <Input type='password' name='Password' id='Password' value={Password} maxLength='30'
                                                        placeholder='Enter your password'
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onFocus={(e) => removeFormError(e.target.id)}
                                                        invalid={!!formErrors.Password}
                                                    >
                                                    </Input>
                                                :
                                                    <Input type='text' name='Password' id='Password' value={Password} maxLength='30'
                                                        placeholder='Enter your password'
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onFocus={(e) => removeFormError(e.target.id)}
                                                        invalid={!!formErrors.Password}
                                                    >
                                                    </Input>
                                                }
                                                <FormFeedback>{formErrors.Password}</FormFeedback>
                                            </FormGroup>
                                            <div className="d-flex justify-content-between">
                                                <FormGroup className='custom-control custom-checkbox'>
                                                    <Input id='RememberMe' className="custom-control-input" type='checkbox' name='RememberMe' checked={rememberMe} onChange={(e) => dispatch(rootAction.userAction.rememberMe(e.target.checked))} />
                                                    <Label for='RememberMe' className="custom-control-label" >
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Remember Me", "lbl_login_rememberme")
                                                            :
                                                            "Remember Me"
                                                        }
                                                    </Label>
                                                </FormGroup>

                                                <Link to={`/${getLanguageCodeFromSession()}/reset-password`} className="forgot-password">
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Forgot Password", "lbl_login_forgotpassword")
                                                        :
                                                        "Forgot Password"
                                                    }
                                                </Link>
                                            </div>
                                            <Button type='submit' color='primary' className='mt-auto' id="lbl_login_loginbtn">
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Login", "lbl_login_loginbtn")
                                                    :
                                                    "Login"
                                                }
                                            </Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </div>
                        }
                        {SignupOption === true ?
                            <div className="col-12 col-md-6 mt-5 mt-md-0">
                                <NewCustomerCard />
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </section>
        </>
    );
    else
        return (
            <>
                <LoadingScreen loading={true} detailText='Loading...' />
            </>
        );
}

export default Login;
