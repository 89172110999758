import { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useSelector } from 'react-redux';
import rootAction from '../../../stateManagment/actions/rootAction';
import Config from '../../../helpers/Config.js';

import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';

import Seo from '../../components/shared/Seo';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import { useGetCartDataQuery } from '../../../services/cart';
import { useGetAddressesQuery, useMeQuery } from '../../../services/user';
import { useConfirmOrderMutation, useGetShipToLocationsQuery, useGetPaymentMethodsQuery, useTokenizeCardMutation, useGetShippingMethodsQuery, useGetAllotmentShippingAddressesQuery } from '../../../services/checkout';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { AddressEditModal } from './AddressEditModal';
import { AddressSummary } from './AddressSummary';
import { OrderSummary } from './OrderSummary';
import { ShippingBagSummary } from './ShippingBagSummary';
import { useSearchParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useCalculateShippingQuery } from "../../../services/cart";
import { ToggleContext } from '../../../ToggleProvider';
import ContentLoader from 'react-content-loader';
import { useGetContentAssetQuery } from '../../../services/content';
import { PaymentMethod } from './PaymentMethod.js';
import { ShippingMethods } from './ShippingMethods.js';

const isAddressValid = (address) => {
    address = address || {};
    return address && address.id && address.firstName && address.lastName && address.address1 && address.city && address.stateCode && address.postalCode && address.countryCode;
}

const Checkout = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const stripeRef = useRef();
    const LocalizationLabelsArray = [];

    const [searchParams, setSearchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState(searchParams.get('WebError') || '');

    // State Selectors
    const cart = useSelector((state) => state.cartReducer);
    const loginUser = useSelector(state => state.userReducer.user);
    const checkoutActivity = useSelector((state) => state.checkout);
    const { siteName, shipToLocationEnabled: siteShipToLocationEnabled, siteDefaultLocation: defaultBillingAddress, shipToLocationEnabled, shipToLocationEnabledForUser, siteLocations, isPayrollDeductionEnabled, shippingAddressEditable, billingAddressEditable, shipToLocationAddressFallback, isShippingTaxExempt, isTaxExemptAppliedToMaxItem, listIgnoreTaxExempt, isDedicatedAllotmentAddressesEnabled, isAddAllotmentAddressEnabled, isPurchaseOrderNumberRequired } = useSelector((state) => state.commonReducer);
    const { user: { EmployeeID, CreditBalance: creditBalance, PayrollDeduction: payrollDeduction, EmployeeLocations: employeeLocations, EmployeeLocation: employeeLocation, CreditEnabled: creditEnabled, UserID: userId, IsOnAccount: isOnAccount } } = useSelector((state) => state.userReducer);
    const { shippingAddress, billingAddress, shippingMethod, editableBilling, hasSelectPaymentOptions, selectedPaymentMethod, shipToLocations, edittedAddress, customerPurchaseOrder } = useSelector((state) => state.checkout);
    const [hasCreditCardPaymentMethod, setHasCreditCardPaymentMethod] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [confirmOrderDisabled, setConfirmOrderDisabled] = useState(false);
    const { isToggled, setIsToggled } = useContext(ToggleContext);

    // Queries
    const { isFetching: isMeFetching } = useMeQuery(null, {
        refetchOnMountOrArgChange: true,
    }); // It's a me.... Mario!
    const { isFetching: isCartFetching, data: cartDataFetched } = useGetCartDataQuery(true);
    const { isFetching: isShipToLocationsFetching, data: shipToLocationsData } = useGetShipToLocationsQuery({
        isOnAccount: selectedPaymentMethod === "ON_ACCOUNT",
        locationId: loginUser?.LocationData?.LocationID,
        costCenterId: loginUser?.LocationData?.CostCenterID
    }, {
        skip: shipToLocations?.length > 0,
        refetchOnMountOrArgChange: true,
    });
    const { data: customerAddresses, isFetching: isCustomerAddressesFetching, isError: isAddressError } = useGetAddressesQuery({
        cartProducts: [],
        userId: loginUser?.UserID
    });
    const { data: paymentMethods, isFetching: isPaymentMethodFetching } = useGetPaymentMethodsQuery(null, {
        refetchOnMountOrArgChange: true,
    });
    const [summaries, setSummaries] = useState(['shipping', 'billing']);

    const { data: shippingMethods, isLoading: isShippingMethodsLoading, isFetching: isShippingMethodsFetching } = useGetShippingMethodsQuery(null, {
        refetchOnMountOrArgChange: true
    });

    const { isFetching: isCalculatFetching } = useCalculateShippingQuery({
        billingAddress,
        shippingAddress,
        shippingId: shippingMethod?.id || shippingMethods?.default_shipping_method_id,
        additionalInfo: {
            isShippingTaxExempt,
            isTaxExemptAppliedToMaxItem,
            isToggled,
            hasSelectPaymentOptions
        }
    }, {
        skip: isShippingMethodsFetching || isCartFetching,
        refetchOnMountOrArgChange: true
    });
    const { isFetching: isAllotmentShippingAddressFetching, data: allotmentShippingAddressesData } = useGetAllotmentShippingAddressesQuery({
        userId: loginUser?.UserID 
    },  {
        skip: !isDedicatedAllotmentAddressesEnabled || isCustomerAddressesFetching
    });

    const getFetchState = () => {
        return !!isCartFetching || !!isCalculatFetching || !!isShippingMethodsFetching || !!isShipToLocationsFetching || !!isCustomerAddressesFetching || !!isAllotmentShippingAddressFetching;
    }

    useEffect(() => {
        setInputDisabled(getFetchState());
        setConfirmOrderDisabled(isConfirmOrderDisabled());
    }, [checkoutActivity.paymentMethods, isCartFetching, isCalculatFetching, isShippingMethodsFetching, isShipToLocationsFetching, isCustomerAddressesFetching, isAllotmentShippingAddressFetching]);

    const isConfirmOrderDisabled = () => {
        // is loading critical info
        if (getFetchState())
            return true;

        // error with the customer address
        if (isAddressError)
            return true;

        // no payment methods
        if (!checkoutActivity.paymentMethods?.length)
            return true;

        if (hasOfflineBlocker())
            return true;

        if (shipToLocationEnabledForUser) {
            // if not custom selection and no ship to locations
            if (!hasSelectPaymentOptions && checkoutActivity?.shipToLocations.length === 0)
                return true;
        }

        return false;
    }

    const hasOfflineBlocker = () => {
        if (hasSelectPaymentOptions && selectedPaymentMethod === 'CREDIT_CARD')
            return false;

        return checkoutActivity.paymentMethods?.filter(pm => pm.hasOfflineBlocker).length > 0;
    };

    useEffect(() => {
        if (selectedPaymentMethod === 'ON_ACCOUNT' && shipToLocations?.length > 1) {
            setAddressModal({ type: 'shipping', open: true, last: selectedPaymentMethod });
        } else setAddressModal({ type: 'shipping', open: false, last: selectedPaymentMethod })
    }, [shipToLocations, selectedPaymentMethod]);

    useEffect(() => {
        if (isShipToLocationsFetching)
            return;

        var isShipToLocationsEnabled = false;
        var shipAddr = shippingAddress ?? customerAddresses?.shippingAddress;
        var shipTo = shipToLocations?.length > 0 ? shipToLocations : shipToLocationsData?.shipToLocations;

        if (shipTo?.length === 0) {
            if (shipToLocationAddressFallback) {
                isShipToLocationsEnabled = false;
                shipAddr = customerAddresses?.shippingAddress;
            }
        }
        else isShipToLocationsEnabled = true;

        if (hasSelectPaymentOptions) {
            isShipToLocationsEnabled = shipTo?.length > 0;
            if (selectedPaymentMethod !== 'ON_ACCOUNT') {
                isShipToLocationsEnabled = false;
                shipAddr = edittedAddress ?? checkoutActivity?.shippingAddress;
            }
            else if (shipTo?.length > 0)
                shipAddr = shipTo[0];
        }

        dispatch(rootAction.commonAction.setUserShipToLocationEnabled(isShipToLocationsEnabled));
        dispatch(rootAction.checkoutActions.setAddressActivity({
            ...checkoutActivity,
            billingAddressEditable,
            editableShipping: shippingAddressEditable,
            shipToLocations: shipTo,
            shipToLocationEnabled,
            firstName: customerAddresses?.shippingAddress?.firstName,
            lastName: customerAddresses?.shippingAddress?.lastName,
            addressBookShippingAddress: shipAddr
        }));
    }, [selectedPaymentMethod, shipToLocationsData])

    useEffect(() => {
        if (!isPaymentMethodFetching && paymentMethods && loginUser?.UserID) {
            const onAccountPaymentMethod = paymentMethods.applicable_payment_methods?.find(pm => pm.id === 'ON_ACCOUNT');
            const creditCardPaymentMethod = paymentMethods.applicable_payment_methods?.find(pm => pm.id === 'CREDIT_CARD');
            setHasCreditCardPaymentMethod(creditCardPaymentMethod && (!onAccountPaymentMethod || !loginUser?.IsOnAccount));
            if (!isToggled) {
                setSummaries(((loginUser?.IsOnAccount && onAccountPaymentMethod) || isPurchaseOrderNumberRequired) ? ['shipping', 'po', 'billing'] : summaries);
            } else {
                setSummaries(['shipping', 'billing']);
            }
        }
    }, [isPaymentMethodFetching, paymentMethods, loginUser]);

    // Initialize address activity
    useEffect(() => {
        if (isInitialized)
            return;
        if (isMeFetching || isCustomerAddressesFetching || isPaymentMethodFetching || isCartFetching || isCalculatFetching || isShipToLocationsFetching || isAllotmentShippingAddressFetching)
            return;

        setIsInitialized(true);

        var billingToUse = {
            ...defaultBillingAddress,
            firstName: siteName,
            lastName: 'HQ'
        };

        var useHQ = (creditBalance + payrollDeduction?.AvailablePDBalance) > cart.orderTotal;
        // force billing to HQ if not CreditCard
        if (useHQ) {
            if (!defaultBillingAddress || defaultBillingAddress.address1.length === 0) {
                showAlert(Config.ERROR_MESSAGES.NO_HQ_ADDRESS);
                return;
            }
        }

        var shipAddr = shippingAddress ?? customerAddresses?.shippingAddress;
        var billAddr = billingAddress ?? customerAddresses?.billingAddress;

        var isShipToLocationsEnabled = false;
        var shipTo = shipToLocations?.length > 0 ? shipToLocations : shipToLocationsData?.shipToLocations;

        if (shipTo?.length === 0) {
            if (shipToLocationAddressFallback) {
                isShipToLocationsEnabled = false;
                shipAddr = customerAddresses?.shippingAddress;
            }
        }
        else isShipToLocationsEnabled = true;

        if (hasSelectPaymentOptions) {
            if (selectedPaymentMethod === 'CREDIT_CARD') {
                useHQ = false;
                billAddr = customerAddresses?.billingAddress;
            }

            if (selectedPaymentMethod !== 'ON_ACCOUNT') {
                isShipToLocationsEnabled = false;
                shipAddr = edittedAddress ?? customerAddresses?.shippingAddress;
            }
            else if (shipTo?.length > 0)
                shipAddr = shipTo[0];
        }
        var allotmentAddresses = isDedicatedAllotmentAddressesEnabled ? allotmentShippingAddressesData : [];
        if (isDedicatedAllotmentAddressesEnabled && allotmentAddresses?.length > 0) {
            shipAddr = allotmentAddresses.find(a => a.c_addressType === 'M');
            if (!shipAddr) {
                shipAddr = allotmentAddresses.find(a => a.c_addressType === 'S');
            }
            //Set allotment address if shipping address hasn't been defined
            if (customerAddresses?.shippingAddress && !isAddressValid(customerAddresses?.shippingAddress)) {
                dispatch(rootAction.checkoutActions.setShippingAddress(shipAddr));
            }
        }
        // Redirect to UpdateProfile page if allotment shipping address is not valid
        if (isDedicatedAllotmentAddressesEnabled && (!shipAddr || !isAddressValid(shipAddr))) {
            navigate(`/${getLanguageCodeFromSession()}/update-profile?redircode=1`);
            return;
        }
        dispatch(rootAction.commonAction.setUserShipToLocationEnabled(isShipToLocationsEnabled));
        dispatch(rootAction.checkoutActions.setAddressActivity({
            ...checkoutActivity,
            employeeLocations,
            employeeLocation,
            siteLocations,
            defaultBillingAddress: billingToUse,
            shipToLocationEnabled,
            shipToLocations: shipTo,
            firstName: customerAddresses?.shippingAddress?.firstName,
            lastName: customerAddresses?.shippingAddress?.lastName,
            billingAddressEditable,
            editableShipping: shippingAddressEditable,
            addressBookShippingAddress: shipAddr,
            addressBookBillingAddress: useHQ ? billingToUse : billAddr,
            paymentMethods,
            customerOnAccount: loginUser?.IsOnAccount,
            paymentAmounts: cart.paymentAmounts,
            isDedicatedAllotmentAddressesEnabled,
            isAddAllotmentAddressEnabled,
            allotmentAddresses: allotmentAddresses
        }));
    }, [isMeFetching, isCustomerAddressesFetching, isPaymentMethodFetching, isCartFetching, isCalculatFetching, isShipToLocationsFetching, selectedPaymentMethod, isAllotmentShippingAddressFetching]);

    // Mutations
    const [confirmOrder, { isLoading: isOrderLoading, isFetching: isOrderFetching }] = useConfirmOrderMutation();
    const [tokenizeCC, { isLoading: isTokenizedCCLoading, isFetching: isTokenizedCCFetching }] = useTokenizeCardMutation();

    const cardTranslation = {
        'visa': 'visa',
        'discover': 'discover',
        'jcb': 'jcb',
        'mastercard': 'master',
        'american-express': 'amex'
    };

    // Local state
    const [addressModal, setAddressModal] = useState({
        type: '',
        open: false,
        isAddNewAllotmentShippingAddress: false
    });

    const toggleAddressModal = () => {
        setAddressModal({
            ...addressModal,
            open: !addressModal.open
        });
    };

    const showAlert = (error) => {
        setErrorMessage(error);
        window.scrollTo({ top: 0, behavior: 'smooth' })
    };

    useEffect(() => {
        if (searchParams.get('WebError')) {
            //remove query string
            searchParams.delete('WebError');
            setSearchParams(searchParams);
        }
    }, [errorMessage]);

    // Redirect if cart is empty
    useEffect(() => {
        if (cartDataFetched && cartDataFetched?.products?.length === 0 && !isCartFetching) {
            if (errorMessage) {
                navigate('/' + getLanguageCodeFromSession() + '/cart?WebError=' + errorMessage);
            } else {
                navigate('/' + getLanguageCodeFromSession() + '/cart');
            }
        }
    }, [isCartFetching, cartDataFetched]);

    // Set payment capabilities
    useEffect(() => {
        if (!isMeFetching && !isCartFetching && !isPaymentMethodFetching && !isCalculatFetching) {
            var gcTotal = 0.0;
            const filteredArray = cart.paymentInstruments.filter(pi => pi.paymentMethodId === 'GIFT_CERTIFICATE');
            filteredArray.forEach(piItem => {
                gcTotal += piItem.availableAmount;
            });
            dispatch(rootAction.checkoutActions.setActivity({
                orderTotal: cart.orderTotal,
                paymentMethods,
                creditBalance: creditBalance,
                pdBalance: payrollDeduction.AvailablePDBalance,
                gcBalance: gcTotal && gcTotal > 0 ? gcTotal : 0.01, //set 1 cent balance to always get GC into the mix
                isPayrollDeductionEnabled,
                creditEnabled,
                isOnAccount,
                isToggled
            }));
        }
    }, [isMeFetching, isPaymentMethodFetching, isCartFetching, isCalculatFetching, cart]);

    // Initialize address activity
    useEffect(() => {
        if (isAddressError && !isCustomerAddressesFetching) {
            //alert error
            showAlert(Config.ERROR_MESSAGES.CHECKOUT_GET_ADDRESS_ERROR);
            return;
        }

        // Redirect to UpdateProfile page if regular shipping address is not valid
        if (!isDedicatedAllotmentAddressesEnabled){
            if (!isCustomerAddressesFetching && customerAddresses && (!isAddressValid(customerAddresses?.billingAddress) || !isAddressValid(customerAddresses?.shippingAddress))) {
                navigate(`/${getLanguageCodeFromSession()}/update-profile?redircode=1`);
                return;
            }
        }
    }, [isCustomerAddressesFetching]);

    useEffect(() => {
        if (!isShippingMethodsFetching && !isCartFetching) {
            const currentShippingMethod = shippingMethods?.applicable_shipping_methods.find((sm) => shippingMethod.id === sm.id);
            const defaultShippingMethod = shippingMethods?.applicable_shipping_methods.find((sm) => sm.id === shippingMethods?.default_shipping_method_id);
            dispatch(rootAction.checkoutActions.setShippingMethod(currentShippingMethod || defaultShippingMethod));
        }
    }, [isShippingMethodsFetching, isCartFetching]);

    useEffect(() => {
        dispatch(rootAction.checkoutActions.setIsInOrderReview(null));
    }, [dispatch]);

    const confirmAction = async (creditCard, tokenResponse) => {
        let paymentAmounts = { ...cart.paymentAmounts };

        if (!paymentAmounts || Object.keys(paymentAmounts)?.length === 0) {
            navigate('/cart?WebError=An unknown error occurred. Please try again.');
            return;
        }

        if (hasSelectPaymentOptions) {
            Object.keys(paymentAmounts).forEach(key => {
                if (key !== selectedPaymentMethod)
                    paymentAmounts[key] = 0;
            });
            dispatch(rootAction.checkoutActions.setPaymentAmounts(paymentAmounts));
        }

        return await confirmOrder({
            shippingAddress: checkoutActivity.shippingAddress,
            shippingMethod: cart.shippingMethod,
            billingAddress: checkoutActivity.billingAddress,
            paymentMethods: checkoutActivity.paymentMethods,
            pdMaxDeductions: Number(checkoutActivity.payrollMaxDeductions || 0),
            ccTokenResponse: tokenResponse,
            creditCard,
            employee: {
                CreditBalance: creditBalance,
                PayrollDeduction: payrollDeduction
            },
            paymentAmounts
        }).unwrap()
            .then(() => {
                rootAction.checkoutActions.setIsInOrderReview(true);
                navigate('/' + getLanguageCodeFromSession() + '/order-review');
            })
    }

    const isPDValid = () => {
        const pdMethod = checkoutActivity.paymentMethods?.find(pm => pm.id === 'UAB_PAYROLL_DEDUCTION');

        if (!pdMethod) // PD not found
            return true;

        // is a special case, but not PD
        if (hasSelectPaymentOptions && selectedPaymentMethod !== 'UAB_PAYROLL_DEDUCTION')
            return true;

        // not special case, or it is special case and PD is selected
        return !!checkoutActivity.payrollMaxDeductions && checkoutActivity.payrollMaxDeductions * 1 !== 0;
    };

    const isCreditCardValid = () => {
        const ccValidation = stripeRef.current.validateCreditCard();

        if (!ccValidation) // CC info is invalid
            return false;

        var ccMethod = paymentMethods.applicable_payment_methods?.find(pm => pm.id === 'CREDIT_CARD');
        if (ccMethod && ccMethod.cards.find(o => o.card_type.toLowerCase() === cardTranslation[stripeRef.current.issuer]) === undefined) {
            // no CC or CC type found
            showAlert('Unfortunately, we don\'t accept ' + stripeRef.current.issuer.split(' ').map(function (word) { return word[0].toUpperCase() + word.substr(1); }).join(' ') + ' card at this time, Please try a different credit card type.');
            return false;
        }

        return true;
    };

    const ConfirmCustomerOrder = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (isPurchaseOrderNumberRequired) {
            dispatch(rootAction.checkoutActions.setCustomerPurchaseOrderError(""));
            var hasText = !!checkoutActivity.customerPurchaseOrder && !!checkoutActivity.customerPurchaseOrder.trim();
            if (!hasText) {
                dispatch(rootAction.checkoutActions.setCustomerPurchaseOrderError("Please fill out this field."));
            }
            dispatch(rootAction.checkoutActions.setIsPurchaseOrderValid(hasText));
            if (!hasText) {
                // Invalid fields
                var poInput = document.querySelector('.is-invalid');
                if (poInput) {
                    window.scrollTo({ top: poInput.offsetTop, behavior: 'smooth' });
                }
                return;
            }
        }

        if (!isPDValid()) {
            showAlert(replaceLoclizationLabel('Checkout_PayrollMaxDeductionsRequired', 'You must select a payroll deduction amount.'));
            return;
        }

        if (!stripeRef.current) {
            try {
                await confirmAction(null, null);
                return;
            } catch (err) {
                showAlert(err.data.message);
                return;
            }
        }

        if (!isCreditCardValid())
            return;

        try {
            const creditCard = {
                ccName: stripeRef.current.name,
                ccNum: stripeRef.current.number,
                ccExp: stripeRef.current.expiry,
                ccCvc: stripeRef.current.cvc,
                ccBrand: stripeRef.current.issuer,
            }
            if (creditCard.ccNum) {
                await tokenizeCC({
                    ...creditCard,
                    billingAddress: checkoutActivity.billingAddress,
                }).unwrap()
                    .then(async (data) => {
                        delete creditCard.ccCvc;
                        dispatch(rootAction.checkoutActions.setCreditCard(creditCard));
                        await confirmAction(creditCard, data.data);
                    })
                    .catch((err) => {
                        showAlert(err?.data?.message ?? 'unknown error occurred');
                    });
            }

        } catch (err) {
            showAlert(err?.data?.message ?? 'unknown error occurred');
        }
    }

    return (
        <>
            <Seo
                title="Checkout"
                description="Checkout"
                keywords="Checkout"
            />
            <LoadingScreen loading={isOrderLoading || isOrderFetching || isTokenizedCCLoading || isTokenizedCCFetching} />
            <SiteBreadcrumb title="Checkout" />

            {addressModal.open &&
                <AddressEditModal
                    type={addressModal.type}
                    isOpen={addressModal.open}
                    toggle={toggleAddressModal}
                    formAllowed={addressModal.editFormAllowed}
                    hasCreditCardPaymentMethod={hasCreditCardPaymentMethod}
                    isAddNewAllotmentShippingAddress={addressModal.isAddNewAllotmentShippingAddress}
                />}
            <form onSubmit={ConfirmCustomerOrder}>
                <section className="checkout-page pb-8 pb-md-9" id="checkout">
                    <div className="container-fluid">
                        <Alert color="danger" className="mb-0" isOpen={errorMessage != ''}>
                            {errorMessage}
                        </Alert>
                        <br />
                        <div className="section-title pb-3">
                            <h1 className="mb-0 mt-md-n2">
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Checkout", "lbl_check_checkout")
                                    :
                                    "Checkout"
                                }
                            </h1>
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                {
                                    summaries.map((type, i) => {
                                        const { shippingAddress, billingAddress } = checkoutActivity;
                                        const title = type === 'shipping' ? 'Shipping' : (type === 'po' ? 'Purchase Order #' : 'Billing');
                                        return (
                                            <div key={i} className={`card mb-5 card-summary ${type === 'shipping' ? 'shipping-summary' : 'billing-summary'}`}>
                                                {(i + 1 || title) &&
                                                    <h5 className="card-header d-flex align-items-center">
                                                        <div className="number-circle">{i + 1}</div>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, title, "lbl_check_shipping")
                                                            :
                                                            title
                                                        }
                                                    </h5>}
                                                <div className="card-body">
                                                    <AddressSummary
                                                        key={type + (type === 'shipping' ? shippingAddress?.id : billingAddress?.id)}
                                                        address={type === 'shipping' ? (checkoutActivity.shippingAddress || shippingAddress) : (checkoutActivity.billingAddress || billingAddress)}
                                                        email={loginUser.EmailAddress}
                                                        type={type}
                                                        subTitle={type === 'shipping' ? 'Shipping Address' : (type === 'po' ? '' : 'Billing Address')}
                                                        setEditAddress={setAddressModal}
                                                        openModal={toggleAddressModal}
                                                        hasCreditCardPaymentMethod={hasCreditCardPaymentMethod}
                                                        isPurchaseOrderNumberRequired={isPurchaseOrderNumberRequired}
                                                    />
                                                    {type === 'billing' &&
                                                        <PaymentMethod
                                                            inputDisabled={inputDisabled}
                                                            stripeRef={stripeRef}
                                                            address={(checkoutActivity.billingAddress || billingAddress)}
                                                        />
                                                    }
                                                    {type === 'shipping' &&
                                                        <ShippingMethods />
                                                    }
                                                </div>
                                            </div >)
                                    })
                                }
                                <div className='row justify-content-end'>
                                    <div className='col-12 col-md-6 col-xl-5 next-step-button pl-4 pr-4'>
                                        {hasOfflineBlocker() && <label className={'text-danger'}>An order is already in Process. Please wait a few hours for the order to complete</label>}
                                        <button type='submit' className='btn btn-primary btn-block' id="lbl_check_placeorder" disabled={confirmOrderDisabled}>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Place Order", "lbl_check_placeorder")
                                                :
                                                "Continue to order review"
                                            }
                                        </button>
                                    </div>
                                </div >
                            </div >

                            <div className="col-md-4">
                                {!isCartFetching ?
                                    !isToggled && <OrderSummary
                                        id={`summary-${cart?.adjustedTotal}`}
                                        key={`summary-${cart?.adjustedTotal}`}
                                        cart={cart}
                                    />
                                    :
                                    !isToggled && <ContentLoader
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                        viewBox="0 0 445 300"
                                        height={300}
                                    >
                                        <rect x="0" y="0" width="100%" height="275" />
                                    </ContentLoader>
                                }
                                <ShippingBagSummary
                                    cart={cart}
                                />
                            </div>
                        </div >
                    </div >
                </section >
            </form >
        </>
    );
}

export default Checkout;
