import { useEffect, useState ,useContext} from "react";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import { Link } from "react-router-dom";
import { GetDefaultCurrencySymbol, replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import { FormGroup, Input, Label } from "reactstrap";
import ShippingInfoModal from "../../components/cart/ShippingInfoModal";
import rootAction from "../../../stateManagment/actions/rootAction";
import { makePriceRoundToTwoPlaces } from "../../../helpers/ConversionHelper";
import { useGetShippingMethodsQuery } from "../../../services/checkout";
import ContentLoader from "react-content-loader";
import { useGetContentAssetQuery } from "../../../services/content";
import { useGetCartDataQuery } from "../../../services/cart";
import { useSelector } from "react-redux";
import { useGetAddressesQuery } from "../../../services/user";
import { ToggleContext } from "../../../ToggleProvider";

export const ShippingMethods = () => {
    const dispatch = useAppDispatch();
    const LocalizationLabelsArray = [];
    const { data: shippingMethodMessage, isFetching: isShippingMethodMessageLoading } = useGetContentAssetQuery('shipping-method-message');
    const [isShippingInfoModalOpen, setIsShippingInfoModalOpen] = useState(false);
    const { billingAddress, shippingAddress } = useSelector((state) => state.checkout);
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const { shippingMethod } = useSelector((state) => state.checkout);
    const loginUser = useSelector(state => state.userReducer.user);
    const toggleShippingInfoModal = (e) => {
        e.preventDefault();
        setIsShippingInfoModalOpen(!isShippingInfoModalOpen);
    }
    const { data: shippingMethods, isLoading: isShippingMethodsLoading, isFetching: isShippingMethodsFetching } = useGetShippingMethodsQuery(null, {
        refetchOnMountOrArgChange: true
    });
    const { isFetching: isCustomerAddressesFetching } = useGetAddressesQuery({
        cartProducts: [],
        userId: loginUser?.UserID
    });
    const { isFetching: isCartFetching } = useGetCartDataQuery(null);

    const changeShippingMethod = (sm) => {
        dispatch(rootAction.checkoutActions.setShippingMethod(sm));
    };

    useEffect(() => {
        if (!isShippingMethodsFetching && !isCartFetching) {
            const currentShippingMethod = shippingMethods?.applicable_shipping_methods.find((sm) => shippingMethod.id === sm.id);
            const defaultShippingMethod = shippingMethods?.applicable_shipping_methods.find((sm) => sm.id === shippingMethods?.default_shipping_method_id);
            dispatch(rootAction.checkoutActions.setShippingMethod(currentShippingMethod || defaultShippingMethod));
        }
    }, [isShippingMethodsFetching, isCartFetching]);


    if (isShippingMethodsLoading || isShippingMethodsFetching || isShippingMethodMessageLoading || isCustomerAddressesFetching || isCartFetching) {
        return <div className='my-4'>
            <ContentLoader
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                viewBox="0 0 600 300"
                height={300}
            >
                <rect x="0" y="0" width="100%" height="300" />
            </ContentLoader>
        </div>
    }

    return (
        <>
            <h6>
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Shipping Method", "lbl_check_shippingmethod")
                    :
                    "Shipping Method"
                }
            </h6>

            <div className="shipping-message clearfix" dangerouslySetInnerHTML={{ __html: shippingMethodMessage?.ContentValue }}></div>

            {shippingMethodMessage?.ContentValue &&
                <Link className='link-underline font-weight-bold d-inline-block text-small mb-4' to='#' onClick={toggleShippingInfoModal}>
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "Learn more about Shipping Methods", "lbl_check_learnmoreaboutshippingmethods")
                        :
                        "Learn more about Shipping Methods"
                    }
                </Link>}

            <ShippingInfoModal open={isShippingInfoModalOpen} toggle={toggleShippingInfoModal} className="modal-shipping-info" />

            <ul className="shipping-methods list-unstyled">
                {shippingMethods?.applicable_shipping_methods.map(sm => {
                    return (
                        <li key={sm.id}>
                            <FormGroup check className='custom-radio custom-control-tall'>
                                <Input
                                    type="radio"
                                    className="custom-control-input"
                                    id={sm.id}
                                    value={sm.id}
                                    name='shipping-method'
                                    onChange={() => changeShippingMethod(sm)}
                                    checked={shippingMethod?.id === sm?.id}
                                />
                                <Label for={sm.id} className="custom-control-label">
                                    <span>
                                        <span className='label'>{sm.name}</span>
                                        {/* {'subtitle'} */}
                                    </span>
                                    {!isToggled&&
                                    <strong className='price'>{sm.price > 0 ? `${GetDefaultCurrencySymbol()}${makePriceRoundToTwoPlaces(sm.price)}` : 'Free'}</strong>
                                    }
                                </Label>
                            </FormGroup>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}