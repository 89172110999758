import React from 'react';
import axios from 'axios';
import Config from './Config';
import { GetTokenForHeader, GetUserIdForHeader,GetCartIDForHeader ,GetEcomCustNoForHeader,GetSiteHqAccountForHeader,GetSiteDefaultSCForHeader,GetBECustNoForHeader,GetCompanyNumberForHeader, GetAllotmentPerepectiveEnabled} from './CommonHelper';

export const MakeApiCallAsync = async (endPointName, methodSubURL, param, headers, methodType, loading = true) => {   
    try {


        //--Add token in header
        if (headers != null && headers != undefined && !headers.hasOwnProperty('Token')) {
            let Token = await GetTokenForHeader();
            headers["Token"] = Token ?? "";
        }

        //--Add user id in header
        if (headers != null && headers != undefined && !headers.hasOwnProperty('UserID')) {

            let UserID = await GetUserIdForHeader();
            headers["UserID"] = UserID ?? "";
        }

        //--Add Cart ID to header
        if (headers != null && headers != undefined && !headers.hasOwnProperty('CartID')) {
            let CartID = await GetCartIDForHeader();
            headers["CartID"] = CartID ?? "";
        }

        //--Add Cust No to header
        if (headers != null && headers != undefined && !headers.hasOwnProperty('EcomCustNo')) {
            let EcomCustNo = await GetEcomCustNoForHeader();
            headers["EcomCustNo"] = EcomCustNo ?? "";
        }

        //-- Add HQ account to header
        if (headers != null && headers != undefined && !headers.hasOwnProperty('SiteHqAccount')) {
            let SiteHqAccount = await GetSiteHqAccountForHeader();
            headers["SiteHqAccount"] = SiteHqAccount ?? "";
        }

        //-- Add Source Code to header
        if (headers != null && headers != undefined && !headers.hasOwnProperty('SiteDefaultSC')) {
            let SiteDefaultSC = await GetSiteDefaultSCForHeader();
            headers["SiteDefaultSC"] = SiteDefaultSC ?? "";
        }

        //--add the AX account of customer to header
        if (headers != null && headers != undefined && !headers.hasOwnProperty('BECustNo')) {
            let BECustNo = await GetBECustNoForHeader();            
            headers["BECustNo"] = BECustNo ?? "";
        }

        //--Add company number to header
        if (headers != null && headers != undefined && !headers.hasOwnProperty('CompanyNumber')) {
            let CompanyNumber = await GetCompanyNumberForHeader();            
            headers["CompanyNumber"] = CompanyNumber?.toString() ?? 0;
        }

        //-- Add sizing portal indicator
        const bSizing  = localStorage.getItem('toggleSizing');
        if(bSizing && bSizing.toString()==="true")
            headers["SizingMode"] = "true";
        else
            headers["SizingMode"] = "false";

        //--Add AllotmentPerepectiveEnabled header
        if (headers != null && headers != undefined && !headers.hasOwnProperty('AllotmentPerepectiveEnabled')) {
            let AllotmentPerepectiveEnabled = await GetAllotmentPerepectiveEnabled();            
            headers["AllotmentPerepectiveEnabled"] = AllotmentPerepectiveEnabled?.toString() ?? "false";
        }


        const URL = (Config['ENV_ADMIN_BASE_URL'] ?? Config['ADMIN_BASE_URL']) + (methodSubURL === null || methodSubURL == undefined ? Config['DYNAMIC_METHOD_SUB_URL'] : methodSubURL) + endPointName;
        methodType = methodType ?? "POST";

        if (methodType === 'POST') {

            const response = await axios.post(URL, param, {
                headers: headers
            });

            //If there iss a valid reAuthToken in the resp data replace the local storage token
            //--set Token in local storage               
            if(response.data.reAuthToken && response.data.reAuthToken.length>0)
                localStorage.setItem("Token", response.data.reAuthToken?? "");

            return response;

        } else if (methodType == 'GET') {
            const response = await axios.get(URL, {
                headers: headers,
                param: param
            });

            //If there iss a valid reAuthToken in the resp data replace the local storage token
            //--set Token in local storage
            if(response.data.reAuthToken && response.data.reAuthToken.length>0)
                localStorage.setItem("Token", response.data.reAuthToken?? "");

           return response;

        }

    } catch (error) {

        return error;
    }

}


export const MakeApiCallSynchronous = (endPointName, methodSubURL, param, headers, methodType, loading = true) => {


    const URL = (Config['ENV_ADMIN_BASE_URL'] ?? Config['ADMIN_BASE_URL']) + (methodSubURL === null || methodSubURL == undefined ? Config['DYNAMIC_METHOD_SUB_URL'] : methodSubURL) + endPointName;

    methodType = methodType ?? "POST";


    if (methodType === 'POST') {
        try {
            const response = axios.post(URL, param, { headers: headers });

            return response;
        }
        catch (error) {

            return error;
        }
    }
    else if (methodType == 'GET') {

        try {
            const response = axios.get(URL, { headers: headers, param: param });

            return response;
        }
        catch (error) {

            return error;
        }
    }
}




