import { useEffect, useRef, useState } from "react";
import { useRefreshTokenMutation } from "../../../services/auth";
import { useSelector } from "react-redux";
import rootAction from "../../../stateManagment/actions/rootAction";
import { reduxStore, useAppDispatch } from "../../../stateManagment/reduxStore";
import { useNavigate } from "react-router-dom";

const RefreshTokenHandler = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [refreshToken] = useRefreshTokenMutation();
    const { TokenValidTo: tokenValidTo, UserID: userId } = useSelector(state => state.userReducer.user);
    const { retokenizing } = useSelector(state => state.commonReducer);
    const [, setSilentlyRetokenizing] = useState(false);
    const [, setIsWindowFocused] = useState(true);
    const SamlLogoutEndpoint = useSelector(state => state.commonReducer.samlLogoutEndpoint);

    useEffect(() => {
        const onFocus = () => setIsWindowFocused(true);
        const onBlur = () => setIsWindowFocused(false);
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        if (document.hasFocus()) {
            onFocus();
        } else {
            onBlur();
        }
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
            dispatch(rootAction.commonAction.isRetokenizing(false));
            dispatch(rootAction.commonAction.sessionExpiringSoon(false));
            dispatch(rootAction.commonAction.sessionExpired(false));
        };
    }, []);

    useEffect(() => {
        const validTo = new Date(tokenValidTo);
        const timeDiffInSeconds = (validTo.getTime() - Date.now()) / 1000;

        if (!userId) return navigate('/login');

        if (tokenValidTo && timeDiffInSeconds < 60 && timeDiffInSeconds > 0) {
            dispatch(rootAction.commonAction.isRetokenizing(true));
            refreshToken()
                .unwrap()
                .then(() => {
                    dispatch(rootAction.commonAction.isRetokenizing(false))
                })
                .catch((e) => {                    
                    dispatch(rootAction.userAction.logOutUser());
                    if(SamlLogoutEndpoint && SamlLogoutEndpoint.length>5){
                        window.location.href =  SamlLogoutEndpoint;
                    }else{
                        navigate('/login');
                    }
                });
        }

        const interval = setInterval(async () => {
            let haveFocus = false;
            let isSilentlyRetokenizing = false;
            const { TokenValidTo: tokenValidTo } = reduxStore.getState().userReducer.user;
            const validTo = new Date(tokenValidTo);
            const { sessionExpired, sessionExpiringSoon } = reduxStore.getState().commonReducer;
            const timeDiffInSeconds = (validTo.getTime() - Date.now()) / 1000;
            setIsWindowFocused((focus) => { haveFocus = focus; return focus; });
            setSilentlyRetokenizing((silentlyRetokenizing) => { isSilentlyRetokenizing = silentlyRetokenizing; return silentlyRetokenizing; });
            if (retokenizing || isSilentlyRetokenizing) return;
            if (haveFocus && timeDiffInSeconds < 60 && timeDiffInSeconds > 0 && !sessionExpired && !sessionExpiringSoon) {
                setSilentlyRetokenizing(true);
                await refreshToken()
                    .unwrap()
                    .then(() => {
                        dispatch(rootAction.commonAction.sessionExpiringSoon(false));
                    })
                    .catch((e) => {
                        navigate('/login');
                    }).finally(() => {
                        setSilentlyRetokenizing(false);
                    });
            } else if (timeDiffInSeconds <= 0) {
                dispatch(rootAction.commonAction.sessionExpired(true));
                clearInterval(interval);
            } else if (timeDiffInSeconds < 60) {
                dispatch(rootAction.commonAction.sessionExpiringSoon(true));
            }
        }, 1000 * 10);
        return () => {
            clearInterval(interval);
            dispatch(rootAction.commonAction.sessionExpiringSoon(false));
            dispatch(rootAction.commonAction.sessionExpired(false));
        }
    }, []);

    return null;
}

export default RefreshTokenHandler;
