import { api } from './api.ts'
import Config from '../helpers/Config'
import { AxiosResponse } from 'axios'
import { userActions } from '../stateManagment/features/userSlice.js'


// Define a service using a base URL and expected endpoints
export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<AxiosResponse, any>({
            query: (credentials: any) => {
                const params = {
                    requestParameters: {
                        ...credentials
                    }
                }
                return {
                    url: Config.END_POINT_NAMES.GET_USER_LOGIN,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: (response: any) => {
                const userData = response.data?.[0]
                if (userData.ResponseMsg !== "Login Successfully") {
                    throw new Error(userData.ResponseMsg)
                }
                return {
                    ...userData,
                    PayrollDeduction: userData.PayrollDeduction || {},
                    SizingTemplate : userData.SizingTemplate ||{},
                    RequiresEmailUpdate: userData?.RequiresEmailUpdate || false
                }
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: userData } = await queryFulfilled;
                    dispatch(userActions.setUser(userData));
                } catch (error) { }
            },
        }),
        switchSession: build.mutation<AxiosResponse, any>({
            query: (credentials: any) => {
                const params = {
                    requestParameters: {
                        ...credentials
                    }
                }
                return {
                    url: Config.END_POINT_NAMES.SWITCH_SESSION,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: (response: any) => {
                const userData = response.data?.[0]
                if (userData.ResponseMsg !== "Login Successfully") {
                    throw new Error(userData.ResponseMsg)
                }
                return {
                    ...userData,
                    PayrollDeduction: userData.PayrollDeduction || {},
                    SizingTemplate : userData.SizingTemplate ||{},
                    RequiresEmailUpdate: userData?.RequiresEmailUpdate || false
                }
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: userData } = await queryFulfilled;
                    dispatch(userActions.setUser(userData));
                } catch (error) { }
            },
        }),        
        loginExt: build.query<AxiosResponse, any>({
            query: (credentials: any) => {
                return {
                    url: "slas/extauth?SP=" + credentials.externalSP + "&LoginSessionID=" + credentials.externalLoginSessionID + "&IsToggled=" + credentials.isToggled,
                    method: 'GET'
                }
            },
            transformResponse: (response: any) => {
                const userData = response.data?.[0]
                return {
                    ...userData,
                    PayrollDeduction: userData?.PayrollDeduction || {},
                    SizingTemplate : userData?.SizingTemplate || {},
                    RequiresEmailUpdate: userData?.RequiresEmailUpdate || false
                }
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: userData } = await queryFulfilled;
                    dispatch(userActions.setUser(userData));
                } catch (error) {}
            },
        }),
        refreshToken: build.mutation<AxiosResponse, any>({
            query: () => {
                return {
                    url: Config.END_POINT_NAMES.REFRESH_TOKEN,
                    method: 'POST',
                    body: {
                        requestParameters: {
                            placeHolder: '[]'
                        }
                    },
                }
            },
            transformResponse: (response: AxiosResponse) => {
                return response.data?.[0]
            }
        }),
        resetPassword: build.mutation<AxiosResponse, any>({
            query: (params) => {
                return {
                    url: Config.END_POINT_NAMES.VALIDATE_OTP_CHANGE_PASSWORD,
                    method: 'POST',
                    body: {
                        requestParameters: {
                            ...params
                        }
                    },
                }
            },
            transformResponse: (response: AxiosResponse) => {
                return response.data
            }
        }),
        sendResetOtp: build.mutation<AxiosResponse, any>({
            query: (params) => {
                return {
                    url: Config.END_POINT_NAMES.INIT_PASSWORD_RESET,
                    method: 'POST',
                    body: {
                        requestParameters: {
                            ...params
                        }
                    },
                }
            },
            transformResponse: (response: AxiosResponse) => {
                return response.data
            }
        }),
    })
})

export const { useLoginMutation, useLoginExtQuery, useRefreshTokenMutation, useSwitchSessionMutation, useResetPasswordMutation, useSendResetOtpMutation } = authApi
