
import { MakeApiCallAsync } from "./ApiHelpers";
import Config from "./Config";
import { checkIfStringIsEmtpy } from "./ValidationHelper";

export const RedirectToWhatsAppPage = () => {
    window.open('https://wa.me/918600614872', '_blank');
}



export const GetDefaultCurrencySymbol = () => {
    let DefaultCurrencySymbol = "$";  //--USD is consider as default if there is no setting in appsetting.json file
    DefaultCurrencySymbol = Config.APP_SETTING['DefaultCurrencySymbol'] ?? "$";
    return DefaultCurrencySymbol;


}

export const GetDefaultCurrencyCode = () => {
    let DefaultCurrencyCode = "USD";  //--USD is consider as default if there is no setting in appsetting.json file
    DefaultCurrencyCode = Config.APP_SETTING['DefaultCurrencyCode'] ?? "USD";
    return DefaultCurrencyCode;


}

export const GetCartIDForHeader = async () => {
    try {
        let CartID = "";

        let cartIdFromStorage = localStorage.getItem("CartID");

        if (cartIdFromStorage != null && cartIdFromStorage != undefined && cartIdFromStorage != "") {

            CartID = cartIdFromStorage;
        }
        return CartID;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }
}

export const GetEcomCustNoForHeader = async () => {
    try {
        let EcomCustNo = "";

        let ecomCustNoFromStorage = localStorage.getItem("EcomCustNo");

        if (ecomCustNoFromStorage != null && ecomCustNoFromStorage != undefined && ecomCustNoFromStorage != "") {

            EcomCustNo = ecomCustNoFromStorage;
        }
        return EcomCustNo;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }
}

export const GetSiteHqAccountForHeader = async () => {
    try {
        let SiteHqAccount = "";

        let HqNoFromStorage = localStorage.getItem("SiteHqAccount");

        if (HqNoFromStorage != null && HqNoFromStorage != undefined && HqNoFromStorage != "") {

            SiteHqAccount = HqNoFromStorage;
        }
        return SiteHqAccount;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }
}

export const GetBECustNoForHeader = async () => {
    try {
        let BECustNo = "";

        let BECustNoFromStorage = localStorage.getItem("BECustNo");

        if (BECustNoFromStorage != null && BECustNoFromStorage != undefined && BECustNoFromStorage != "") {

            BECustNo = BECustNoFromStorage;
        }
        return BECustNo;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }
}

export const GetCompanyNumberForHeader = async () => {
    try {
        let CompanyNumber = "";

        let CompanyNumberFromStorage = localStorage.getItem("CompanyNumber");

        if (CompanyNumberFromStorage != null && CompanyNumberFromStorage != undefined && CompanyNumberFromStorage != 0) {

            CompanyNumber = CompanyNumberFromStorage;
        }
        return CompanyNumber;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }
}

export const GetSiteDefaultSCForHeader = async () => {
    try {
        let SiteDefaultSC = "";

        let ScFromStorage = localStorage.getItem("SiteDefaultSC");

        if (ScFromStorage != null && ScFromStorage != undefined && ScFromStorage != "") {

            SiteDefaultSC = ScFromStorage;
        }
        return SiteDefaultSC;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }
}


export const GetTokenForHeader = async () => {


    try {
        let Token = "";

        let tokenFromStorage = localStorage.getItem("Token");

        if (tokenFromStorage != null && tokenFromStorage != undefined && tokenFromStorage != "") {

            Token = tokenFromStorage;
        }
        return Token;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }

}

export const IsValidSession = () => {
    let Token = "";
    let TokenValid = false;
    const loginUser = JSON.parse(localStorage.getItem("user") ?? null);
    let tokenFromStorage = localStorage.getItem("Token");
    if (tokenFromStorage != null && tokenFromStorage != undefined && tokenFromStorage != "") {

        Token = tokenFromStorage;
    }
    if (Token != null && Token != undefined && Token != "") {
        const jwtPayload = JSON.parse(window.atob(Token.split('.')[1]));
        if (new Date().getTime() < jwtPayload.exp * 1000) {
            TokenValid = true;
        }
    }
    return loginUser && loginUser.UserID && TokenValid;
}

export const GetUserIdForHeader = async () => {


    try {
        let UserID = "";

        let loginUserDataJson = localStorage.getItem("user");
        const loginUser = JSON.parse(loginUserDataJson ?? "{}");

        if (loginUser != null && loginUser != undefined && loginUser != "") {

            UserID = loginUser.UserID;
        }
        return UserID;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }

}

export const setLanguageCodeInSession = async (LangCode) => {


    try {
        let lCode = LangCode ?? "en";
        localStorage.setItem("langCode", lCode);
    }
    catch (err) {
        console.error(err.message);
    }

}

export const getLanguageCodeFromSession = () => {
    let langCode = "en";

    try {
        langCode = localStorage.getItem("langCode");
    }
    catch (err) {
        console.error(err.message);
    }
    if (!checkIfStringIsEmtpy(langCode)) {
        return ("en");
    } else {
        return (langCode);
    }

}

export const GetLocalizationControlsJsonDataForScreen = async (entityId, htmlElementId = null) => {

    let responseArray = [];


    try {

        let languageCode = getLanguageCodeFromSession();


        if (languageCode == undefined || languageCode == null || languageCode == "") {
            return responseArray;
        }

        if (languageCode == "en") { //-- do not perform localization for english
            return responseArray;
        }

        if (entityId == undefined || entityId == null || entityId == "" || entityId < 1) {
            return responseArray;
        }

        const headerLocalization = {
            Accept: 'application/json',
            'Content-Type': 'application/json',

        }


        const paramLocalization = {
            requestParameters: {
                entityId: entityId,
                languageCode: languageCode,
                recordValueJson: "[]",
            },
        };



        const localizationResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_LOCALIZATION_CSTM_PORTAL'], Config['COMMON_CONTROLLER_SUB_URL'], paramLocalization, headerLocalization, "POST", true);
        if (localizationResponse != null && localizationResponse.data != null) {

            let finalData = JSON.parse(localizationResponse.data.data);
            responseArray = finalData.labelsJsonData;

        }

    } catch (error) {
        console.log(error.message);

    }
    return responseArray;
}


// minify the embroideryConfiguration
export const minifyJSON = (unminifiedJSON) => {
    var minifiedJSON = '';
    if (unminifiedJSON) {
        minifiedJSON = unminifiedJSON.replace(/attributeValue/g, '#av').replace(/componentAttributes/g, '#cs').replace(/componentAttribute/g, '#ca') // eslint-disable-line
            .replace(/decimalPrice/g, '#dp').replace(/pricebookPrice/g, '#pb').replace(/webDisplayValue/g, '#wd').replace(/valueDesc/g, '#vd'); // eslint-disable-line
    }
    return minifiedJSON;
}

// Unminify the embroideryConfiguration
export const unminifyJSON = (minifiedJSON) => {
    var unminifiedJSON = '';
    if (minifiedJSON) {
        unminifiedJSON = minifiedJSON.replace(/#vd/g, 'valueDesc').replace(/#wd/g, 'webDisplayValue').replace(/#pb/g, 'pricebookPrice') // eslint-disable-line
            .replace(/#dp/g, 'decimalPrice').replace(/#ca/g, 'componentAttribute').replace(/#cs/g, 'componentAttributes').replace(/#av/g, 'attributeValue'); // eslint-disable-line
    }
    return unminifiedJSON;
}

// transform to lowercase the item embroideryConfiguration
export const transformEmbroideryJSON = (toTransformJSON) => {
    var result = '';
    if (toTransformJSON) {
        result = toTransformJSON.replaceAll('AttributeValueDescription', 'attributeValueDescription') // eslint-disable-line
            .replaceAll('ComponentAttributes', 'componentAttributes') // eslint-disable-line
            .replaceAll('ComponentAttribute', 'componentAttribute') // eslint-disable-line
            .replaceAll('Component', 'component') // eslint-disable-line
            .replaceAll('Price', 'price') // eslint-disable-line
            .replaceAll('AttributeValue', 'attributeValue'); // eslint-disable-line
    }
    return result;
}

export const replaceLoclizationLabel = (labelsJsonData, defaultTxt, labelHtmlId) => {
    let labelTxt = "";
    try {

        if (labelsJsonData == null || labelsJsonData == undefined || labelsJsonData.length == 0) {
            return (defaultTxt);
        }

        let foundObj = labelsJsonData.find(elem => elem.labelHtmlId === labelHtmlId);
        labelTxt = !checkIfStringIsEmtpy(foundObj?.text) === true ? defaultTxt : foundObj?.text;
    }
    catch (err) {
        console.error(err.message);
        labelTxt = defaultTxt;
    }

    return (labelTxt)
}


export const ScrollIntoSpecificDiv = (divId, behaviorParam) => {
    try {
        document.getElementById(divId).scrollIntoView({
            behavior: behaviorParam ?? 'smooth',
            // block: 'center',
            // inline: 'start'
        });
    } catch (error) {
        console.error("An error occurred:", error);
    }
}

export const calculateContrastRatio = function (color1, color2) {
    // Helper function to calculate luminance
    function getLuminance(color) {
        const rgb = color.map(value => {
            value /= 255; // Convert to the range [0, 1]
            return value <= 0.03928
                ? value / 12.92
                : Math.pow((value + 0.055) / 1.055, 2.4);
        });

        return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
    }

    if (color1 && !Array.isArray(color1)) {
        color1 = hexToRgb(color1);
    }
    if (color2 && !Array.isArray(color2)) {
        color2 = hexToRgb(color2);
    }

    if (!color1 || !color2) {
        return NaN;
    }

    // Calculate luminance for both colors
    const luminance1 = getLuminance(color1);
    const luminance2 = getLuminance(color2);

    // Find the greater and smaller luminance values
    const [lMax, lMin] = luminance1 > luminance2 ? [luminance1, luminance2] : [luminance2, luminance1];

    // Calculate the contrast ratio
    const contrastRatio = (lMax + 0.05) / (lMin + 0.05);

    return Number(contrastRatio.toFixed(2)); // Return the contrast ratio rounded to 2 decimal places
}

export const hexToRgb = function (hex) {
    // Remove the hash symbol (#) if it exists
    hex = hex.replace(/^#/, '');

    // Parse the hexadecimal string into individual components
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
}

export const GetAllotmentPerepectiveEnabled = async () => {
    try {
        let AllotmentPerepectiveEnabled = "false";

        let ApeFromStorage = localStorage.getItem("AllotmentPerepectiveEnabled");

        if (ApeFromStorage != null && ApeFromStorage != undefined && ApeFromStorage != "") {

            AllotmentPerepectiveEnabled = ApeFromStorage;
        }
        return AllotmentPerepectiveEnabled;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }
}