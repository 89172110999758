import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import { useGetContentAssetQuery } from '../../../services/content';

export const SiteLeftSidebarNav = (props) => {
    const LocalizationLabelsArray = [];
    const { data: additionalContent, isFetching: isAdditionalContentLoading } = useGetContentAssetQuery('leftnav-additional-content');
    const leftNavContent = [
        {title: 'Customer Service', langkey: 'lbl_sidebar_customercare', id: 'title-custserv', links: [
            {title: 'FAQs', langkey: 'lbl_sidebar_faqs', url: `/${getLanguageCodeFromSession()}/faq`},
            {title: 'Order Status', langkey: 'lbl_sidebar_orderstatus', url: `/${getLanguageCodeFromSession()}/orders-history`},
            {title: 'Returns', langkey: 'lbl_sidebar_returns', url: `/${getLanguageCodeFromSession()}/returns`},
            {title: 'Modify My Order', langkey: 'lbl_sidebar_modifymyorder', url: `/${getLanguageCodeFromSession()}/order-modification`},
            {title: 'My Account', langkey: 'lbl_sidebar_myaccount', url: `/${getLanguageCodeFromSession()}/my-account`},
            {title: 'Sizing Information', langkey: 'lbl_sidebar_sizinginformation', url: `/${getLanguageCodeFromSession()}/sizing-information`}
        ]},
        {title: 'Company', langkey: 'lbl_sidebar_company', id: 'title-company', links: [
            {title: 'Home', langkey: 'lbl_sidebar_home', url: `/${getLanguageCodeFromSession()}/`},
            {title: 'About Us', langkey: 'lbl_sidebar_aboutus', url: `/${getLanguageCodeFromSession()}/about`},
            {title: 'Contact Us', langkey: 'lbl_sidebar_contactus', url: `/${getLanguageCodeFromSession()}/contact-us`},
            {title: 'Privacy Policy', langkey: 'lbl_sidebar_privacypolicy', url: `/${getLanguageCodeFromSession()}/privacy-policy`},
            {title: 'Terms & Conditions', langkey: 'lbl_sidebar_termsandconditions', url: `/${getLanguageCodeFromSession()}/terms`}
        ]}
    ];

    const [navContent, setNavContent] = useState(leftNavContent);
    useEffect(() => {
        if (isAdditionalContentLoading || additionalContent?.ContentValue?.length === 0)
            return;

        var data = JSON.parse(additionalContent?.ContentValue ?? "[]") ;
        var newContent = JSON.parse(JSON.stringify(leftNavContent));

        data.forEach((obj) => {
            if (obj?.position === 0) {
                newContent[0].links.push(...Object.values(obj?.links));
                return;
            } else if (obj?.position > leftNavContent?.length) {
                newContent.push(obj);
                return;
            }

            newContent.splice(obj.position - 1, 0, obj);
        });
        setNavContent(newContent);
    }, [isAdditionalContentLoading]);

    const [isExpanded, setIsExpanded] = useState(false);
    const handleClick = event => {
        setIsExpanded(current => !current);
    };
    const location = useLocation();

    return (
        <>
        <div className="col-12 col-md-3 d-none d-md-block">
            <div className={isExpanded ? "sidebar-navigation active" : "sidebar-navigation"}>
                <button aria-controls="sidebar-content" aria-expanded={isExpanded ? "true" : "false"} className="sidebar-navigation-toggle d-block d-md-none" onClick={handleClick}>
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "Customer Service", "lbl_sidebar_customerservice")
                        :
                        "Customer Service"
                    }
                </button>
                <nav id="sidebar-content" title={props.title}>
                {navContent.map((e, idx) => (
                    <div key={e.langkey + idx} className="sidebar-navigation-block">
                        <h3>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, e.title, e.langkey)
                                :
                                e.title
                            }
                        </h3>
                        <ul>
                            {e.links.map((l, i) => (
                                <li key={l.langkey + i}>
                                    <Link to={l.url} target={l.newTab ? '_blank' : ''} className={location.pathname === l.url ? "active" : "not-active"}>
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, l.title, l.langkey)
                                            :
                                            l.title
                                        }
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
                </nav>
            </div>
        </div>
        </>
    );
}