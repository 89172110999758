import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingScreen from "./web/components/shared/LoadingScreen";
import RefreshTokenHandler from "./web/components/shared/RefreshTokenHandler";
import ContentLoader from "react-content-loader";
import { SessionTimeoutModal } from "./web/components/modal/SessionTimeoutModal";
import { useAppDispatch } from "./stateManagment/reduxStore";
import { useEffect } from "react";
import rootAction from "./stateManagment/actions/rootAction";

const ProtectedRoutes = ({ children }) => {
  const {pathname} = useLocation();
  const dispatch = useAppDispatch();
  const isRetokenizing = useSelector(state => state.commonReducer.retokenizing)
  const user = useSelector(state => state.userReducer.user)

  useEffect(() => {
    // remember last visited url
    dispatch(rootAction.commonAction.setLastVisitedUrl(pathname));
  }, [pathname, dispatch]);

  useEffect(() => {
    // remember last user logged on
    dispatch(rootAction.commonAction.setLastUserId(user?.UserID));
  }, [user, dispatch]);
  
  if (isRetokenizing) {
    return (
      <div className="container">
        <LoadingScreen loading={isRetokenizing} />
        <ContentLoader
          speed={2}
          width={1280}
          height={900}
          viewBox="0 0 1280 900"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="753" y="419" rx="5" ry="5" width="510" height="2" />
          <rect x="147" y="24" rx="0" ry="0" width="540" height="540" />
          <rect x="12" y="25" rx="0" ry="0" width="104" height="109" />
          <rect x="11" y="165" rx="0" ry="0" width="104" height="109" />
          <rect x="11" y="305" rx="0" ry="0" width="105" height="109" />
          <rect x="753" y="27" rx="0" ry="0" width="160" height="18" />
          <rect x="753" y="63" rx="0" ry="0" width="496" height="32" />
          <rect x="753" y="189" rx="0" ry="0" width="45" height="16" />
          <rect x="753" y="224" rx="0" ry="0" width="80" height="80" />
          <rect x="11" y="451" rx="0" ry="0" width="105" height="109" />
          <rect x="73" y="479" rx="0" ry="0" width="2" height="14" />
          <rect x="753" y="127" rx="20" ry="20" width="101" height="32" />
          <rect x="872" y="128" rx="21" ry="21" width="85" height="32" />
          <rect x="972" y="125" rx="20" ry="20" width="146" height="32" />
          <rect x="1133" y="127" rx="20" ry="20" width="66" height="32" />
          <rect x="828" y="190" rx="0" ry="0" width="175" height="16" />
          <rect x="937" y="194" rx="0" ry="0" width="8" height="8" />
          <rect x="854" y="224" rx="0" ry="0" width="80" height="80" />
          <rect x="954" y="224" rx="0" ry="0" width="80" height="80" />
          <rect x="753" y="309" rx="0" ry="0" width="80" height="5" />
          <rect x="753" y="364" rx="0" ry="0" width="53" height="16" />
          <rect x="828" y="348" rx="25" ry="25" width="432" height="42" />
          <rect x="753" y="459" rx="0" ry="0" width="140" height="32" />
          <rect x="1150" y="521" rx="0" ry="0" width="6" height="4" />
          <rect x="1157" y="496" rx="0" ry="0" width="49" height="5" />
          <rect x="753" y="504" rx="0" ry="0" width="70" height="12" />
          <rect x="753" y="549" rx="25" ry="25" width="435" height="42" />
          <rect x="1101" y="459" rx="25" ry="25" width="48" height="48" />
          <rect x="1213" y="459" rx="25" ry="25" width="48" height="48" />
          <rect x="1172" y="461" rx="0" ry="0" width="16" height="30" />
          <rect x="1212" y="547" rx="25" ry="25" width="48" height="48" />
          <rect x="12" y="627" rx="0" ry="0" width="175" height="18" />
          <circle cx="20" cy="675" r="8" />
          <rect x="40" y="670" rx="0" ry="0" width="331" height="12" />
          <circle cx="20" cy="701" r="8" />
          <rect x="40" y="696" rx="0" ry="0" width="331" height="12" />
          <circle cx="20" cy="727" r="8" />
          <rect x="40" y="722" rx="0" ry="0" width="331" height="12" />
          <circle cx="20" cy="754" r="8" />
          <rect x="40" y="749" rx="0" ry="0" width="331" height="12" />
          <rect x="11" y="800" rx="0" ry="0" width="566" height="12" />
          <rect x="11" y="882" rx="0" ry="0" width="566" height="12" />
          <rect x="11" y="817" rx="0" ry="0" width="566" height="12" />
          <rect x="11" y="838" rx="0" ry="0" width="566" height="12" />
          <rect x="11" y="860" rx="0" ry="0" width="566" height="12" />
          <rect x="895" y="635" rx="0" ry="0" width="175" height="18" />
          <circle cx="903" cy="683" r="8" />
          <rect x="923" y="678" rx="0" ry="0" width="331" height="12" />
          <circle cx="903" cy="709" r="8" />
          <rect x="923" y="704" rx="0" ry="0" width="331" height="12" />
          <circle cx="903" cy="735" r="8" />
          <rect x="923" y="730" rx="0" ry="0" width="331" height="12" />
          <circle cx="903" cy="762" r="8" />
          <rect x="923" y="757" rx="0" ry="0" width="331" height="12" />
        </ContentLoader>
      </div>
    )
  }

  return (user.JWTToken && user.JWTToken !== "") ?
    <>
      <RefreshTokenHandler />
      <SessionTimeoutModal />
      {children}
    </>
    :
    <Navigate to="/login" />;
};

export default ProtectedRoutes;